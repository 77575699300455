<template>
    <div class="landingContainer">
        <div class="mt-3">
            <h2 class="py-1 px-3 text-center text-black font_size_2" v-html="$t('getCustomers')"></h2>
            <h4 class="py-1 px-3 text-center text-black p_header" v-html="$t('getCustomers1')"></h4>
            <h4 class="py-1 px-3 text-center text-black p_header" v-html="$t('getCustomers2')"></h4>
        </div>

        <div class="d-md-flex justify-content-center mt-5">
            <div class="col col-md-6 d-flex justify-content-center align-items-center mb-5">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/HyaIzBnGbF0?controls=0"
                    frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    title="La solución para el sector del cuidado personal - As Saloon" allowfullscreen></iframe>
            </div>

            <div class="col col-md-6 d-flex justify-content-center" ref="requestFormContainer">
                <card class="col col-md-12 containerForm mx-3 p-4">
                    <p class="text-black mb-3 text-center cta_2">{{ $t('promotions50Clients') }}</p>
                    <form @submit.prevent="startTest" class="text-center">
                        <base-input name="nameandlastname" class="text-left" v-model="form.name"
                            :label="`${$t('nameAndLastname')}*`" :placeholder="$t('nameAndLastname')" required>
                        </base-input>

                        <base-input name="business" class="text-left" v-model="form.company_name"
                            :label="`${$tc('business', 1)}*`" :placeholder="$tc('business', 1)">
                        </base-input>

                        <base-input inputType="tel" name="phone" class="text-left" v-model="form.phone"
                            :label="`${$t('phone')}*`" :placeholder="$t('phone')" required>
                        </base-input>

                        <base-input inputType="email" name="email" class="text-left" v-model="form.email"
                            :label="`${$t('email')}*`" :placeholder="$t('email')" required>
                        </base-input>
                        <div class="col col-sm-10 col-md-12">
                            <hr>
                            <h4 class="text-black mb-2 text-center font-weight-bold">{{ $t('startFreeTrialForm_title')
                                }}</h4>
                            <base-button native-type="submit" :disabled="disabledBtn" type="success"
                                class="mb-2 startTestBtn py-3 px-2">
                                <span class="text-center font_size mx-auto">{{ $t('startFreeTrialForm_btn') }}</span>
                                <img src="@/assets/img/icons/btn_right1.png" width="20px" height="20px"
                                    class="ml-2 my-auto" />
                                <Spinner v-if="sending" classes="text-info ml-4"></Spinner>
                            </base-button>
                            <hr>

                        </div>

                    </form>
                </card>
            </div>
        </div>
        <div class="d-md-flex flex-md-column px-4 py-5 mt-5 bg-white justify-content-center">
            <div class="py-2 d-md-flex justify-content-center ">
                <div class="col col-md-10 p-0 pl-md-0">
                    <h2 class="py-1 px-3 text-center text-black phrase-cta">{{ $t("marketingSection.introducction") }}
                    </h2>
                    <h4 class="pt-1 px-3 text-center  text-dark font_size">{{ $t("marketingSection.introducction1") }}
                    </h4>
                </div>
            </div>
            <div class="d-md-flex justify-content-center">
                <div class="col col-md-8 p-0 pl-md-0">
                    <ul class="ul_content pb-5">
                        <li v-html="$t('marketingSection.desired_situation.description_1')"></li>
                        <li v-html="$t('marketingSection.desired_situation.description_2')"></li>
                        <li v-html="$t('marketingSection.desired_situation.description_3')"></li>
                        <li v-html="$t('marketingSection.desired_situation.description_4')"></li>
                        <li v-html="$t('marketingSection.desired_situation.description_5')"></li>
                    </ul>
                </div>
            </div>

            <div class="">
                <div class="py-2 d-md-flex justify-content-center">
                    <div class="col col-md-8 p-0 pl-md-0">
                        <h2 class="py-1 px-3 text-center text-black font-weight-bold">
                            {{ $t("marketingSection.ourSolution.title") }}</h2>
                    </div>
                </div>
                <div class="d-md-flex justify-content-center">
                    <div class="col col-md-8 p-0 pl-md-0">
                        <ul class="ul_content">
                            <li v-html="$t('marketingSection.ourSolution.description_1')"></li>
                            <li v-html="$t('marketingSection.ourSolution.description_2')"></li>
                            <li v-html="$t('marketingSection.ourSolution.description_3')"></li>
                            <li v-html="$t('marketingSection.ourSolution.description_4')"></li>
                            <li v-html="$t('marketingSection.ourSolution.description_5')"></li>
                        </ul>
                    </div>
                </div>
                <div class="block d-md-flex justify-content-center my-5 text-center">
                    <div class="col col-sm-8 col-md-8 px-0">
                        <hr>
                        <h4 class="text-black mb-2 text-center font-weight-bold">{{ $t('startFreeTrialNew_tittle') }}
                        </h4>
                        <base-button type="success" class="startFreeTrialBtn " @click="goTo('requestFormContainer')">
                            <span class="text-center mx-auto">{{ $t('startFreeTrialNew_btn') }}</span>
                            <img src="@/assets/img/icons/btn_right1.png" width="20px" height="20px"
                                class="ml-2 my-auto" />
                        </base-button>
                        <hr>
                    </div>
                </div>
                <div class="d-md-flex justify-content-center">
                    <div class="col col-md-8 p-0 pl-md-0">
                        <div class="pt-3">
                            <h3 class="py-1 px-3 text-center text-black font-weight-bold">{{ $t("notPeopleAlong.title")
                                }}
                                {{ $t("notPeopleAlong.subText") }}</h3>
                            <h4 class="py-1 px-3 text-black font_size" v-html="$t('notPeopleAlong.description')"></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="my-5">
            <Testimonials />
        </section>
        <section class="my-5">
            <ToolsApp />
        </section>
        <div class="bg-white p-2">
            <div class="m-1">
                <div class="card px-1 py-4">
                    <div class="landingContainer">
                        <div class="d-md-flex justify-content-between mt-4">
                            <div class="col col-sm-12 col-md-6 justify-content-center">
                                <card class="col col-sm-12 containerForm py-4 bg_gradient">
                                    <div class="header_tariff">
                                        <h2 class="text-black mb-3 text-center font-weight-bold">
                                            {{ $t('tariffSelect.basic.title') }}</h2>
                                        <h1 class="text-black my-4 text-center font-weight-bold"> €15 </h1>
                                        <h4 class="text-black mb-3 text-center"> {{ $t('tariffSelect.basic.price') }}
                                        </h4>
                                    </div>
                                    <div class="lst_tariff py-4">
                                        <ul class="ul_tariff pl-1 ">
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item1') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item2') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item3') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item4') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item5') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item6') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item7') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item8') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/invalid.png" width="18px" height="18px"
                                                    class="mr-2" />{{ $t('tariffSelect.basic.item9') }}</li>
                                        </ul>
                                    </div>
                                    <div class="block d-md-flex justify-content-center mt-4 text-center">
                                        <div class="col col-sm-12 px-0">
                                            <hr>
                                            <h4 class="text-black mb-2 text-center font-weight-bold">{{
                $t('tariffSelect.basic.btn_title') }}</h4>
                                            <base-button type="success" class="startFreeTrialBtn"
                                                @click="goTo('requestFormContainer')">
                                                <span class="text-center mx-auto">{{ $t('tariffSelect.basic.btn')
                                                    }}</span>
                                                <img src="@/assets/img/icons/btn_right1.png" width="20px" height="20px"
                                                    class="ml-2 my-auto" />
                                            </base-button>
                                            <hr>
                                        </div>
                                    </div>
                                </card>
                            </div>
                            <div class="col col-sm-12 col-md-6 justify-content-center">
                                <card class="col col-sm-12 containerForm  py-4 bg_gradient">
                                    <div class="header_tariff">
                                        <h2 class="text-black mb-3 text-center font-weight-bold">
                                            {{ $t('tariffSelect.premium.title') }}</h2>

                                        <h1 class="text-black my-4 text-center font-weight-bold"> €25 </h1>
                                        <h4 class="text-black mb-3 text-center"> {{ $t('tariffSelect.premium.price') }}
                                        </h4>
                                    </div>
                                    <div class="lst_tariff py-4">
                                        <ul class="ul_tariff pl-1">
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item1') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item2') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item3') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item4') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item5') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item6') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item7') }}
                                            </li>
                                            <li><img src="@/assets/img/icons/invalid.png" width="18px" height="18px"
                                                    class="mr-2" />{{ $t('tariffSelect.basic.item8') }}</li>
                                            <li><img src="@/assets/img/icons/icon_success.png" width="30px"
                                                    height="30px" class="mr-2" />{{ $t('tariffSelect.basic.item9') }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="block d-md-flex justify-content-center mt-4 text-center">
                                        <div class="col col-sm-12 px-0">
                                            <hr>
                                            <h4 class="text-black mb-2 text-center font-weight-bold">{{
                $t('tariffSelect.premium.btn_title') }}</h4>
                                            <base-button type="success" class="startFreeTrialBtn"
                                                @click="goTo('requestFormContainer')">
                                                <span class="text-center m-auto">{{ $t('tariffSelect.premium.btn')
                                                    }}</span>
                                                <img src="@/assets/img/icons/btn_right1.png" width="20px" height="20px"
                                                    class="ml-2 my-auto" />
                                            </base-button>
                                            <hr>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-5 text-center">
            <p class="text-dark phrase-cta my-2">{{ $t('earnMoreWithYourBusiness') }}</p>

            <div class="block d-md-flex justify-content-center">
                <div class="block d-md-flex justify-content-center mt-4 text-center">
                    <div class="col col-sm-12 px-0">
                        <hr>
                        <h4 class="text-black mb-2 text-center font-weight-bold">{{ $t('startFreeTrialNew_tittle') }}
                        </h4>
                        <base-button type="success" class="startFreeTrialBtn" @click="goTo('requestFormContainer')">
                            <span class="text-center mx-auto">{{ $t('startFreeTrialNew_btn') }}</span>
                            <img src="@/assets/img/icons/btn_right1.png" width="20px" height="20px"
                                class="ml-2 my-auto" />
                        </base-button>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseInput, Card, Spinner, Testimonials, ToolsApp } from "@/components/index";
import { mapState } from 'vuex';

export default {
    data() {
        return {
            form: {
                name: '',
                company_name: '',
                phone: '',
                email: '',
            },
            sending: false,
            isViewDesktop: false,
        }
    },
    computed: {
        disabledBtn() {
            return this.sending || Object.values(this.form).some(value => !value) || this.checkEmailIsInvalid(this.form.email);
        },
        ...mapState(["defaultTitle"]),
    },
    methods: {
        async startTest() {
            this.sending = true;

            const response = await this.axios.post('/users/contact', this.form)
            if (response && response.data.status === "success") {
                this.$toast.success(this.$t("notifications.success.contact"));
                this.sending = false;
                this.form = {};
            }
        },
        checkViewDesktop() {
            this.isViewDesktop = window.innerWidth >= 768;
        },
        goTo(destiny) {
            if (this.$refs && this.$refs[destiny]) {
                this.$refs[destiny].scrollIntoView({ block: "center", behavior: "smooth" })
            }
        },
        checkEmailIsInvalid(value) {
            const mailFormat = /^[\w-.]+@([\w-]+\.)+([\w-]+)$/;
            return !mailFormat.test(value);
        },
        setGroups() {
            let tools = ["appointmentManagement", "reminders", "statistics", "controlEmployees", "managerFunctions", "loyalty", "CMS", "protectionPolicies"]
            this.groups = tools.map(item => {
                let group = {
                    isActive: false,
                    title: this.$t(`marketingSection.descriptionTools.${item}.title`),
                    description: this.$t(`marketingSection.descriptionTools.${item}.description`),
                }
                return group
            })
        }

    },
    metaInfo() {
        return {
            title: `${this.$t('footerLink.joinUs')} - ${this.defaultTitle}`,
            meta: [
                { name: 'og:title', property: 'og:title', content: `${this.$t('footerLink.joinUs')} - ${this.defaultTitle}` },
                { name: 'og:type', property: 'og:type', content: 'website' },
                { name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}` },
                { name: 'og:description', property: 'og:description', content: this.$t('vueMeta.businessInformation.description') },
                { name: 'description', content: this.$t('vueMeta.businessInformation.description') },
                { name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME }
            ]
        }
    },
    components: {
        BaseInput,
        Card,
        Spinner,
        Testimonials,
        ToolsApp
    },
    created() {
        window.addEventListener("resize", this.checkViewDesktop);
    },
    mounted() {
        this.checkViewDesktop();
    },
    destroyed() {
        window.removeEventListener("resize", this.checkViewDesktop);
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.p_header {
    font-weight: 400 !important;
}

.font_size {
    font-size: 1.2em !important;
}

.font_size_2 {
    font-size: 2.2em !important;
}

.bg_gradient {
    background-image: linear-gradient(to bottom, #5de5cb, #e5f9f6, #fff, #fff, #fff) !important;
}

.ul_content li {
    font-size: 1.2em !important;
    line-height: 2.0 !important;
}

.header_tariff {
    height: 20% !important;
}

.lst_tariff {
    height: 65% !important;
}

.ul_tariff li {
    font-size: 1.2em !important;
    line-height: 1.5 !important;
    list-style: none !important;
    margin-bottom: 15px !important;
}

.btn_tariff {
    height: 15% !important;
    margin-top: 10px !important;

}

i {
    font-size: 50px;
}

.image {
    border: 10px solid black;
    height: 30em;
    width: 19em;
    border-radius: 1rem;
}

.containerForm {
    border-radius: 1em;
}

.phrase {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 3.5rem;
}

.phrase-cta {
    font-size: 2.2em;
    font-weight: bold;
}

.cta {
    font-size: 1.64em;
    font-weight: bold;
}

.cta_2 {
    font-size: 1.2em;
    font-weight: bold;
}

.startFreeTrialBtn {
    font-size: 1.2em;
    padding: 15px 10px !important;
    display: inline-flex !important;
}

.startFreeTrialBtn>span,
.startTestBtn>span {
    white-space: break-spaces;
}

.startTestBtn {
    display: inline-flex !important;
}

.svgIcon {
    width: 5em;
}

.card-cto {
    border: 2px solid $primary;
}

.landing-img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 1rem;
}

.landingContainer {
    max-width: 1500px;
    margin: 0 auto;

    .header-icons-texts {
        font-weight: normal;
        font-size: 15px;
    }

    .bg-white {
        border-radius: 1rem;
    }

    .text-cta {
        h2 {
            color: #088375;
            margin: 10px 0;
            font-size: 2rem;
        }

        h3 {
            margin-bottom: 10px !important;
        }
    }

    .description-text {
        font-weight: 400 !important;

        span {
            font-weight: bolder !important;
        }
    }
}

@media (min-width: 768px) {
    .containerForm {
        max-width: 450px;
    }

    .title-text {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: left;
    }

    .description-text {
        font-size: 1rem;
    }
}

@media (MAX-width: 767px) {
    .startFreeTrialBtn {
        font-size: 1.1em;
        padding: 15px 5px !important;
        display: inline-flex !important;
    }
}
</style>